<template>
  <div class="okOtherTry">
    <h3>OK-{{postData.S_OP_Json.S_OP_CG_Try_Brand}}</h3>

    <div><el-button type="info" @click="tb">同步数据</el-button></div>
    <p>屈光度</p>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>FK</li>
          <li>SK</li>
          <li>视力</li>
          <li>HVID</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_CG_R_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_XOD')" v-model="postData.S_OP_Json.S_OP_CG_R_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_FKOD')" v-model="postData.S_OP_Json.S_OP_CG_R_FKOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_SKOD')" v-model="postData.S_OP_Json.S_OP_CG_R_SKOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_VAOD')" v-model="postData.S_OP_Json.S_OP_CG_R_VAOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_HVIDOD')" v-model="postData.S_OP_Json.S_OP_CG_R_HVIDOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_CG_R_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_R_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_XOS')" v-model="postData.S_OP_Json.S_OP_CG_R_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_FKOS')" v-model="postData.S_OP_Json.S_OP_CG_R_FKOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_SKOS')" v-model="postData.S_OP_Json.S_OP_CG_R_SKOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_VAOS')" v-model="postData.S_OP_Json.S_OP_CG_R_VAOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_R_HVIDOS')" v-model="postData.S_OP_Json.S_OP_CG_R_HVIDOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>

    <p>试戴片数据</p>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>类型</li>
          <li>AC</li>
          <li>RP</li>
          <li>DIA</li>
          <li>CP</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_TypeOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><el-input @change="zh('S_OP_CG_Try_ACOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_ACOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_RPOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_RPOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_DIAOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_DIAOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_CPOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_CPOD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_Try_TypeOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><el-input @change="zh('S_OP_CG_Try_ACOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_ACOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_RPOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_RPOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_DIAOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_DIAOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_CPOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_CPOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>

    <p>片上验光</p>
    <div class="srA">
      <div>
        <ul class="content-sr padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>矫正视力</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DSOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DCOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_XOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_XOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_VAOD')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_VAOD" placeholder="请输入内容"></el-input></li>

        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DSOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_DCOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_XOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_XOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('S_OP_CG_Try_OnDiopter_VAOS')" v-model="postData.S_OP_Json.S_OP_CG_Try_OnDiopter_VAOS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>

    <p>试戴评估</p>
    <div class="srA">
      <div class="bts">动态评估</div>
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>中心定位</li>
          <li>移动度</li>
          <li>泪液交换</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LocationOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zxdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ActivityOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in ydd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LYJHOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lyjh"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LocationOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zxdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ActivityOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in ydd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LYJHOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lyjh"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>
    <div class="tp">
      <UPfile :type="'VST_D'" :showimg="dtimg" @sctpfn="uptps"></UPfile>
<!--      <el-upload-->
<!--          action="#"-->
<!--          list-type="picture-card"-->
<!--          :auto-upload="false">-->
<!--        <i slot="default" class="el-icon-plus"></i>-->
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <img-->
<!--              class="el-upload-list__item-thumbnail"-->
<!--              :src="file.url" alt=""-->
<!--          >-->
<!--          <span class="el-upload-list__item-actions">-->
<!--        <span-->
<!--            class="el-upload-list__item-preview"-->
<!--            @click="handlePictureCardPreview(file)"-->
<!--        >-->
<!--          <i class="el-icon-zoom-in"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleDownload(file)"-->
<!--        >-->
<!--          <i class="el-icon-download"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleRemove(file)"-->
<!--        >-->
<!--          <i class="el-icon-delete"></i>-->
<!--        </span>-->
<!--      </span>-->
<!--        </div>-->
<!--      </el-upload>-->
<!--      <el-dialog :visible.sync="dialogVisible">-->
<!--        <img width="100%" :src="dialogImageUrl" alt="">-->
<!--      </el-dialog>-->
    </div>
    <div class="srA">
      <div class="bts">静态评估</div>
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>中央光学区</li>
          <li>反转区</li>
          <li>着陆区</li>
          <li>边缘区</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_CenterOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zygxq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ReversalOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in fzq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LandOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zlq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_EdgeOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in byq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_CenterOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zygxq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ReversalOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in fzq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_LandOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zlq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_EdgeOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in byq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>
    <div class="srA">
      <div class="bts" style="opacity: 0;">静态评估</div>
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>镜片直径</li>
          <li>佩戴情况</li>
          <li>改进</li>
          <li>睡眠测试</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_FitOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in jpzj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_TrySituationOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in pdqk"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ImproveOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in gj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_SleepTestOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in smcs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_FitOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in jpzj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_TrySituationOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in pdqk"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_ImproveOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in gj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_CG_A_SleepTestOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in smcs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>
    <div class="tp">
      <UPfile :type="'VST_J'" :showimg="jtimg" @sctpfn="uptps"></UPfile>
<!--      <el-upload-->
<!--          action="#"-->
<!--          list-type="picture-card"-->
<!--          :auto-upload="false">-->
<!--        <i slot="default" class="el-icon-plus"></i>-->
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <img-->
<!--              class="el-upload-list__item-thumbnail"-->
<!--              :src="file.url" alt=""-->
<!--          >-->
<!--          <span class="el-upload-list__item-actions">-->
<!--        <span-->
<!--            class="el-upload-list__item-preview"-->
<!--            @click="handlePictureCardPreview(file)"-->
<!--        >-->
<!--          <i class="el-icon-zoom-in"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleDownload(file)"-->
<!--        >-->
<!--          <i class="el-icon-download"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleRemove(file)"-->
<!--        >-->
<!--          <i class="el-icon-delete"></i>-->
<!--        </span>-->
<!--      </span>-->
<!--        </div>-->
<!--      </el-upload>-->
<!--      <el-dialog :visible.sync="dialogVisible">-->
<!--        <img width="100%" :src="dialogImageUrl" alt="">-->
<!--      </el-dialog>-->
    </div>

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.S_OP_Json.S_OP_CG_Remark">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "okOtherTry",
  data() {
    return {
      value: '',
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,

      lx: [
        {
          value: '常规',
          label: '常规'
        },
        {
          value: '电浆',
          label: '电浆'
        },
        {
          value: '散光',
          label: '散光'
        }
      ],

      zxdw: [
        {
          value: '中央',
          label: '中央'
        },
        {
          value: '上偏',
          label: '上偏'
        },
        {
          value: '下偏',
          label: '下偏'
        },
        {
          value: '鼻偏',
          label: '鼻偏'
        },
        {
          value: '颞偏',
          label: '颞偏'
        }
      ],
      ydd: [
        {
          value: '好，约等于1~2mm',
          label: '好，约等于1~2mm'
        },
        {
          value: '差，<1mm',
          label: '差，<1mm'
        },
        {
          value: '不动',
          label: '不动'
        },
        {
          value: '荣光素不进',
          label: '荣光素不进'
        },
        {
          value: '大，>2mm',
          label: '大，>2mm'
        },
        {
          value: '垂直顺滑',
          label: '垂直顺滑'
        },
        {
          value: '动摇不定，易偏位',
          label: '动摇不定，易偏位'
        },
        {
          value: '顶部旋转',
          label: '顶部旋转'
        },
        {
          value: '眼部控制',
          label: '眼部控制'
        }
      ],
      lyjh: [
        {
          value: '好',
          label: '好'
        },
        {
          value: '可接受',
          label: '可接受'
        },
        {
          value: '差',
          label: '差'
        }
      ],

      zygxq: [
        {
          value: '无或淡荧光区，直径约4mm',
          label: '无或淡荧光区，直径约4mm'
        },
        {
          value: '<4mm，与反转区分界不清',
          label: '<4mm，与反转区分界不清'
        },
        {
          value: '出现荧光积聚',
          label: '出现荧光积聚'
        },
        {
          value: '中央荧光素较多且弥散',
          label: '中央荧光素较多且弥散'
        },
        {
          value: '淡荧光染色，呈椭圆形',
          label: '淡荧光染色，呈椭圆形'
        }
      ],
      fzq: [
        {
          value: '均匀荧光亮区，宽约1mm',
          label: '均匀荧光亮区，宽约1mm'
        },
        {
          value: '高荧光，荧光宽带，边界不清',
          label: '高荧光，荧光宽带，边界不清'
        },
        {
          value: '反转区荧光带宽，中央区小',
          label: '反转区荧光带宽，中央区小'
        },
        {
          value: '下方染色积液',
          label: '下方染色积液'
        },
        {
          value: '侧方染色积液',
          label: '侧方染色积液'
        },
        {
          value: '有气泡',
          label: '有气泡'
        }
      ],
      zlq: [
        {
          value: '全周与角膜平行接触，呈均匀淡荧光环，约1mm',
          label: '全周与角膜平行接触，呈均匀淡荧光环，约1mm'
        },
        {
          value: '与角膜接触过紧，呈荧光黑区',
          label: '与角膜接触过紧，呈荧光黑区'
        },
        {
          value: '与角膜非环形接触，荧光环不完整',
          label: '与角膜非环形接触，荧光环不完整'
        },
        {
          value: '荧光逃逸',
          label: '荧光逃逸'
        }
      ],
      byq: [
        {
          value: '宽约0.5mm的荧光区亮',
          label: '宽约0.5mm的荧光区亮'
        },
        {
          value: '荧光带较细',
          label: '荧光带较细'
        },
        {
          value: '荧光带消失',
          label: '荧光带消失'
        },
        {
          value: '镜片边缘翘起，宽荧光带',
          label: '镜片边缘翘起，宽荧光带'
        },
        {
          value: '可见气泡',
          label: '可见气泡'
        }
      ],

      jpzj: [
        {
          value: '合适',
          label: '合适'
        },
        {
          value: '偏大',
          label: '偏大'
        },
        {
          value: '偏小',
          label: '偏小'
        }
      ],
      pdqk: [
        {
          value: '好',
          label: '好'
        },
        {
          value: '陡峭拒绝',
          label: '陡峭拒绝'
        },
        {
          value: '平坦拒绝',
          label: '平坦拒绝'
        },
        {
          value: '陡峭可接受',
          label: '陡峭可接受'
        },
        {
          value: '平坦可接受',
          label: '平坦可接受'
        }
      ],
      gj: [
        {
          value: '改成散光片',
          label: '改成散光片'
        },
        {
          value: '加大直径',
          label: '加大直径'
        },
        {
          value: '减少直径',
          label: '减少直径'
        },
        {
          value: '收紧AC',
          label: '收紧AC'
        },
        {
          value: '放松AC',
          label: '放松AC'
        },
        {
          value: '抬高PC',
          label: '抬高PC'
        }
      ],
      smcs: [
        {
          value: '中心',
          label: '中心'
        },
        {
          value: '上偏',
          label: '上偏'
        },
        {
          value: '下偏',
          label: '下偏'
        },
        {
          value: '鼻偏',
          label: '鼻偏'
        },
        {
          value: '颞偏',
          label: '颞偏'
        },
        {
          value: '抬不成环',
          label: '抬不成环'
        },
        {
          value: '中央岛',
          label: '中央岛'
        }
      ],

      jtimg: [],
      dtimg: []
    }
  },
  props: {
    name: {
      default: ''
    },
    postData: {
      default: {}
    }
  },
  created() {
    this.jtimg = this.postData.S_OP_Json.S_OP_JTPics ? this.postData.S_OP_Json.S_OP_JTPics.split(',') : []
    this.dtimg = this.postData.S_OP_Json.S_OP_DTPics ? this.postData.S_OP_Json.S_OP_DTPics.split(',') : []

   if (this.dtimg.length < 1 && this.postData.S_OP_Json.S_OP_DTPics) {
     this.dtimg = [this.postData.S_OP_Json.S_OP_DTPics]

   }

    if (this.jtimg.length < 1 && this.postData.S_OP_Json.S_OP_JTPics) {
      this.jtimg = [this.postData.S_OP_Json.S_OP_JTPics]
    }

    // console.log(this.dtimg)
  },
  methods: {
    handleRemove(file) {
      //console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      //console.log(file);
    },
    tb() {
      let datas = this.$store.state.eyeTest.refractiveData.S_OP_Json
      //console.log(datas)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DSOD', datas.S_OP_R_SS_DSOD)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DCOD', datas.S_OP_R_SS_DCOD)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_XOD', datas.S_OP_R_SS_XOD)

      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DSOS', datas.S_OP_R_SS_DSOS)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_DCOS', datas.S_OP_R_SS_DCOS)
      this.$set(this.postData.S_OP_Json, 'S_OP_CG_R_XOS', datas.S_OP_R_SS_XOS)
    },

    zh(key, type=0) {
      let str = this.postData.S_OP_Json[key]
      if (str !== '' && Number(str) > 0) {
        if (type) {
          this.postData.S_OP_Json[key] = "+" + str
        }else {
          this.postData.S_OP_Json[key] = str.toString()
        }
      }else {
        this.postData.S_OP_Json[key] = str !== ''?str.toString():''
      }
    },

    uptps(data) {
      if (data.type == 'VST_D' && data.cazuo == 'add') {
        this.dtimg.push(data.url)
        this.postData.S_OP_Json.S_OP_DTPics = this.dtimg.join(',')
      }else if (data.type == 'VST_J' &&  data.cazuo == 'add') {
        this.jtimg.push(data.url)
        this.postData.S_OP_Json.S_OP_JTPics = this.jtimg.join(',')
      }

      if (data.type == 'VST_D' && data.cazuo == 'dele') {
        for (let i = 0; i < this.dtimg.length; i++) {
          if (this.dtimg[i] == data.url) {
            this.dtimg.splice(i, 1)
            break
          }
        }
        this.postData.S_OP_Json.S_OP_DTPics = this.dtimg.join(',')
      }else if (data.type == 'VST_J' &&  data.cazuo == 'dele') {
        for (let i = 0; i < this.jtimg.length; i++) {
          if (this.jtimg[i] == data.url) {
            this.jtimg.splice(i, 1)
            break
          }
        }
        this.postData.S_OP_Json.S_OP_JTPics = this.jtimg.join(',')
      }
    }
  },
  components: {
    UPfile: () => import('./upFile')
  }
}
</script>

<style scoped lang="scss">
h3 {padding-top: .3rem; text-align:left;}
.mar-20 {margin-top: 30px}
.padding-20{padding-top: 15px;}
.srA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  .bts {padding-top: 1em; padding-right: .3rem}
  p {text-align: left;padding-top: .2rem;}
  .content-sr {
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      width: 8vw;
      margin-right: 40px;
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
    }
    li:nth-child(1) {
      width: 3vw;
      text-align: left;
      margin-right: 5px;
    }
  }
  .xz {
    li {width: 13vw}
  }
  .tj {
    input {
      display: block;
      width: 100%;
      outline: none;
      border: 1px solid #000000;
      border-radius: 5px;
      padding: 5px 0;
      text-align: center;
      margin-top: 21px;
    }
  }
  .last {
    margin-left: 50px;
    margin-top: 32px;
  }
}
.bz p {padding-bottom: 10px;font-size: 17px; text-align: left;}
.tp {
  display: flex;
  justify-content: start;
  padding-top: 0.4rem;
}
p {
  padding-top: .3rem;
  text-align: left;
}
</style>
